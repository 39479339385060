import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AuthProvider, { useAuth } from 'Context/AuthContext';
import Spinner from 'Components/Spinner';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const AppSelector: React.FC = () => {
  const { user } = useAuth();

  React.useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <React.Suspense fallback={<Spinner style={{width: '5rem', height: '5rem'}} />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppSelector />
      </Router>
    </AuthProvider>
  );
};

export default App;
