import React from 'react';

const Spinner: React.FC<{
  style?: React.CSSProperties;
  className?: string;
  hideSr?: boolean;
  fullscreen?: boolean;
}> = ({ style, className, hideSr, children }) => {
  return (
    <div className={'spinner-border text-primary ' + className} role='status' style={style}>
      {!hideSr && <span className='sr-only'>Loading...</span>}
      {children}
    </div>
  );
};

export default Spinner;
