import React from 'react';
import { useAsync } from 'react-async';

export interface IAuthUser {
  id: string;
  userName: string;
  groups: number[];
}

export interface IAuthState {
  user?: IAuthUser;
}

export interface IAuthMethods {
  signout: () => void;
  signin: (username: string, password: string, rememberme: boolean) => Promise<void>;
  forgotPassword: (email: string) => Promise<Response>;
}

export type IAuthContext = IAuthState & IAuthMethods;

async function CheckUser(): Promise<IAuthUser | undefined> {
  try {
    const req = await fetch('/api-v2/auth', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
    if (req.status !== 200) throw new Error((await req.json()).error);
    const data = await req.json();
    return data;
  } catch {
    console.log('error');
    return undefined;
  }
}

async function SignOut() {
  try {
    await fetch('/api-v2/auth/signout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
    return true;
  } catch {
    return false;
  }
}

async function SignIn(username: string, password: string, rememberme: boolean) {
  const req = await fetch('/api-v2/auth/login', {
    method: 'POST',
    body: JSON.stringify({ username, password, rememberme }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });

  if (req.status !== 200) throw new Error((await req.json()).error);

  return await req.json();
}

function ForgotPassword(email: string) {
  return fetch('/api-v2/auth/forgot-password', {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

const AuthProvider: React.FC = props => {
  const { data, isLoading, reload } = useAsync({ promiseFn: CheckUser });

  if (isLoading) return <div>Loading...</div>;

  const signin = (username: string, password: string, rememberme: boolean) =>
    SignIn(username, password, rememberme).then(reload);
  const signout = () => SignOut().then(reload);
  const forgotPassword = (email: string) => ForgotPassword(email);

  return <AuthContext.Provider value={{ user: data, signin, signout, forgotPassword }} {...props} />;
};

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) throw new Error('useAuth must be used within an AuthProvider');
  return context;
}

export default AuthProvider;
